import { URI } from '../constants/defaultConstants';
import { RequestService } from './RequestService';
import { AuthService } from './AuthService';

export const TranslationsService = {
	async getAllRequests(page = 1) {
		try {
			const response = await RequestService({}).getRequest(
				// `${URI.translations}?per_page=5&page=${page}`
				`${URI.translations}?per_page=${5 * page}`
			);
			return response;
		} catch (error) {
			console.log('getAllRequests error:', error);
		}
	},
	async getAllUsers(page = 1, limit = 5) {
		try {
			const response = await RequestService({}).getRequest(
				`${URI.getContacts}?page=${page}&limit=${limit}`
			);
			return response;
		} catch (error) {
			console.log('getAllUsers error:', error);
		}
	},
	async getUser(id) {
		try {
			const response = await RequestService({}).getRequest(
				`${URI.getContactData}/${id}`
			);
			return response;
		} catch (error) {
			console.log('getUser error:', error);
		}
	},
	// async patchTranslation(id, translations) {
	// 	try {
	// 		const response = await RequestService({}).patchRequest(
	// 			// `${URI.translations}`,
	// 			`${URI.translations}/response/upload`,
	// 			{
	// 				...translations,
	// 				id
	// 			}
	// 		);
	// 		return response;
	// 	} catch (error) {
	// 		console.log('patchTranslation error:', error);
	// 	}
	// },
	async patchTranslation(data) {
		// for (var pair of data.entries()) {
		// 	// console.log(pair[0]+ ', ' + pair[1], 'formData log');
		// 	// console.log('element type', typeof pair[1]);
		// }

		for (var value of data.values()) {
			console.log(value, typeof value);
		 }

		try {
			const response = await RequestService({
				data,
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${AuthService?.getUser()?.token}`,
					'x-interpreter-app-type': 'connectoperator',
					'x-interpreter-locale': 'uk',
				},
			}).postRequest(
				// `${URI.translations}`,
				`${URI.translations}/response/upload`
			);
			return response;
		} catch (error) {
			console.log('patchTranslation error:', error);
		}
	},

	async patchStatus(data) {
		try {
			const response = await RequestService({
				data,
			}).patchRequest(
				`${URI.translations}/status`
			);
			// console.log('success', response);

			return response;
		} catch (error) {
			console.log('patchStatus error:', error);
		}
	},

	async postMessage(data) {
		try {
			const response = await RequestService({
				data,
			}).postRequest(
				`${URI.translations}/refuse`
			);
			// console.log('success', response);

			return response;
		} catch (error) {
			console.log('postMessage error:', error);
		}
	}
};
