import { configureStore } from '@reduxjs/toolkit';
import translationsReducer from './Translations/TranslationsSlice';
import paginationReducer from './Pagination/PaginationSlice';
import recordsReducer from './Records/RecordsSlice';
import fileReducer from './Translations/FileSlice';

export const store = configureStore({
	reducer: {
		translationsReducer,
		paginationReducer,
		recordsReducer,
		fileReducer
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
