// import { translationsListType } from './TranslationsSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type translationType = {
	id: number;
	user_id: number;
	interpreter_id: number | null;
	files: string[];
	status: 'created' | 'processing' | 'completed';
	created_at: Date;
	updated_at: Date;
	user?: User | null,
};

export type Member = {
	id: number,
	phone: string,
	userId: number,
	firstName: string,
	lastName: string,
	patronymic: string,
	identification: any,
	placeOfRegistration: any,
	validated: boolean,
	msekFront: any,
	msekBack: any,
	msekNumber: any,
	msekSerial: any
};

export type User = {
	id: number,
	activity: boolean,
	region: any,
	blockedTimes: number,
	blockedTill: any,
	deletedAt: any,
	createdAt: string,
	updatedAt: string | null,
	login: string,
	passwodr: any,
	languages: any,
	profileId: number | null,
	member?: Member | null
};

// export type translationsListType = {
// 	from: number;
// 	to: number;
// 	per_page: number;
// 	total: number;
// 	current_page: number;
// 	prev_page: number | null;
// 	next_page: number | null;
// 	last_page: number | null;
// 	data: translationType[];
// };

export type translationsInitialState = {
	all: translationType[],
	users: User[] | null,
	selectedId: number,
	selectedFileId: number | null,
	isPressed: boolean,
	signedForTranslation: boolean,
	refusedToTranslate: boolean,
	currentTranslations: translationType | null,
	completed: boolean
};

const initialState: translationsInitialState = {
	all: [],
	users: null,
	selectedId: 0,
	selectedFileId: null,
	isPressed: false,
	signedForTranslation: false,
	refusedToTranslate: false,
	currentTranslations: null,
	completed: false
};

export const translationsSlice = createSlice({
	name: 'translations',
	initialState,
	reducers: {
		translationsGetAll: (
			state,
			action: PayloadAction<translationType[]>
		) => {
			state.all = action.payload;
		},
		translationsGetUsers: (
			state,
			action: PayloadAction<User[]>
		) => {
			state.users = action.payload;
		},
		selectTranslation: (
			state,
			action: PayloadAction<number>
		) => {
			state.selectedId = action.payload;
		},
		selectFile: (
			state,
			action: PayloadAction<number>
		) => {
			state.selectedFileId = action.payload;
		},
		setIsPressed: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.isPressed = action.payload;
		},
		signForTranslation: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.signedForTranslation = action.payload;
		},
		refuseToTranslate: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.refusedToTranslate = action.payload;
		},
		setCurrentTranslations: (
			state,
			action: PayloadAction<translationType>
		) => {
			state.currentTranslations = action.payload;
		},
		setCompleted: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.completed = action.payload;
		}
	},
});

export const {
	translationsGetAll,
	translationsGetUsers,
	selectTranslation,
	selectFile,
	setIsPressed,
	signForTranslation,
	refuseToTranslate,
	setCurrentTranslations,
	setCompleted
} = translationsSlice.actions;

export default translationsSlice.reducer;
