import React, { useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService } from '../services/AuthService';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const InterpreterPanel = ({ authState }) => {
	const [StyledBadge, setStyledBadge] = React.useState(
		withStyles((theme) => ({
			badge: {
				backgroundColor: '#44b700',
				color: '#44b700',
				boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
				'&::after': {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					borderRadius: '50%',
					animation: '$ripple 1.2s infinite ease-in-out',
					border: '1px solid currentColor',
					content: '""',
				},
			},
			'@keyframes ripple': {
				'0%': {
					transform: 'scale(.8)',
					opacity: 1,
				},
				'100%': {
					transform: 'scale(2.4)',
					opacity: 0,
				},
			},
		}))(Badge)
	);
	const [status, setStatus] = useState('online');
	const interpreter = AuthService.getUser() || authState;
	const classes = useStyles();

	useEffect(() => {
		let color;
		switch (status) {
			case 'online':
				color = '#44b700';
				break;
			case 'away':
				color = '#fbff00';
				break;
			case 'busy':
				color = '#ff0000';
				break;
			case 'break':
				color = '#ac00b7';
				break;
			case 'lunch':
				color = '#0321b3';
				break;
			case 'invisible':
				color = '#5700b7';
				break;
			default:
				break;
		}
		setStyledBadge(
			withStyles((theme) => ({
				badge: {
					backgroundColor: color,
					color: color,
					boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
					'&::after': {
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						borderRadius: '50%',
						animation: '$ripple 1.2s infinite ease-in-out',
						border: '1px solid currentColor',
						content: '""',
					},
				},
				'@keyframes ripple': {
					'0%': {
						transform: 'scale(.8)',
						opacity: 1,
					},
					'100%': {
						transform: 'scale(2.4)',
						opacity: 0,
					},
				},
			}))(Badge)
		);
	}, [status]);

	setTimeout(() => {
		setStatus('away');
	});

	return (
		<></>
	);
};

const styles = (theme) => ({
	customBadge: {
		backgroundColor: (props) => props.color,
		color: (props) => props.color,
		minWidth: 10,
		height: 10,
	},
});

function SimpleBadge(props) {
	const { classes } = props;
	return (
		<Badge classes={{ badge: classes.customBadge }} badgeContent={' '} />
	);
}

const SimpleStyledBadge = withStyles(styles)(SimpleBadge);

const useStyles = makeStyles((theme) => ({
	interpreterPanel: {
		color: '#fff',
		backgroundColor: theme.palette.type === 'dark' ? '#000000' : '#3f51b5',
		padding: '15px 10px',
	},
}));
