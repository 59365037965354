import { RequestService } from './RequestService'
import { URI } from '../constants/defaultConstants'
import { useContext } from 'react'
import { AuthContext } from '../reducers/AuthReducer'

export const AuthService = {
    async signIn(credentials, cb) {
        try {
            const user = await RequestService({}).instance.post(
                URI.login,
                credentials
            )

            cb(user)
            return user
        } catch (error) {
            console.error('indent', error)
            throw error
        }
    },
    async signOut(cb, login) {
        try {
            await RequestService({}).instance.post(URI.logout, {
                login: await this.getUser(login).email
            })
            cb()
        } catch (error) {
            console.log('ERROR LOGOUT')
            this.setUser(null)
        }
    },

    async blockUser({ session_id }) {
        try {
            const response = await RequestService({
                headers: {
                    'X-Interpreter-Token': AuthService.getUser().auth_token,
                    'Content-Type': 'application/json'
                },
                data: { session_id }
            }).postRequest(URI.blockClient)

            console.log('response block user: ', response)
        } catch {
            console.log('cant block user')
        }
    },
    getUser() {
        return JSON.parse(
            localStorage.getItem(`${process.env.REACT_ENV_TYPE}_user`)
        )
    },
    setUser(user) {
        localStorage.setItem(
            `${process.env.REACT_ENV_TYPE}_user`,
            JSON.stringify(user)
        )
    }
}
// hgajxdwv
